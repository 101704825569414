@import "../css/_brand_colors.scss";
@import "../css/globals";

:root {
    --layout-background: #FFF;
}

.modal {
    background: var(--Background-colours-B4---Modal-bg, rgba(0, 0, 0, 0.70));
    display: block;
    margin: 0 auto;
}

.layout {
    width: 580px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 4px 4px 0 0;
    background-color: #FFF;
    border-color: #000;
}

.header {
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.body {
    background-color: #FFF;
}

.footer {
    background-color: #FFF;
}

.container {
    width: 520px;
    color: var(--Text-Colours-T1-Title, #000);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

a {
    color: var(--Text-Colours-T5---Sec-Button, #1F46A1);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}